import FavouriteViews from 'components/Dashboard/FavouriteViews/FavouriteViews.vue';
import RecentlyUploadedViews from 'components/Dashboard/RecentlyUploadedViews/RecentlyUploadedViews';
import RecentViews from 'components/Dashboard/RecentViews/RecentViews.vue';
import GenericSnackBar from 'components/GenericSnackbar/GenericSnackBar.vue';
import mainMixin from 'js/mixins/mainMixin';
import Vue from 'vue';

// eslint-disable-next-line no-new
new Vue({
    name: 'Home',
    components: {
        RecentViews,
        RecentlyUploadedViews,
        FavouriteViews,
        GenericSnackBar,
    },
    mixins: [mainMixin],
    data: {
        cards: {
            showHelp: true,
            showGuide: true,
        },
        // eslint-disable-next-line no-undef
        favouriteViews,
    },
    computed: {},
    watch: {},
    mounted() {
        if (localStorage.showGuideCard === 'false')
            this.cards.showGuide = false;
        if (localStorage.showHelpCard === 'false') this.cards.showHelp = false;
    },
    methods: {
        hideHelpCard() {
            this.cards.showHelp = false;
            localStorage.showHelpCard = 'false';
        },
        hideGuideCard() {
            this.cards.showGuide = false;
            localStorage.showGuideCard = 'false';
        },
        updateFavourites() {
            this.$refs.favourites.update();
        },
        addToFavourites(view) {
            this.$refs.favourites.favouriteViews.push(view);
        },
        snack(message) {
            this.$refs.snack.show(message);
        },
        changeFavourites(favourites) {
            this.favouriteViews = favourites;
        }
    },
});
